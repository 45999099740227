@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        -webkit-appearance: none;
        background-color: #F5F5F5;
        height: 5px !important;
        border-radius: 2.5px !important;
        border-color: #F5F5F5;
        cursor: pointer;
    }

    input[type='range']::-webkit-slider-runnable-track {
        margin-top: -1px;
    }

    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 14px;
        height: 14px;
        border-radius: 7px !important;
        background: #F16C00;
    }

}

.bottom-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 10px 0 10px;
    border-color: #333333 transparent transparent transparent;
    border-radius: 2px;
}

/* used for card information dropdown */
select {
    -webkit-appearance: none;
}

.tooltip {
    visibility: hidden;
    position: absolute;
}

.has-tooltip:hover .tooltip {
    visibility: visible;
    z-index: 100;
}

.has-tooltip:focus .tooltip {
    visibility: visible;
    z-index: 100;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* ********************** */