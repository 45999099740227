.apexcharts-datalabels-group,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
    font-family: 'Quicksand', sans-serif !important;
}

.apexcharts-datalabel-label {
    padding: 0 !important;
    transform: translateY(-5x);
}

.apexcharts-datalabel-value {
    padding: 0 !important;
    transform: translateY(-15px);
}